<template>
    <div v-bind="$attrs">
        <div style="line-height: 1;" @mouseover="showPinned = true" @mouseleave="showPinned = false">
            <small v-if="!_comment.IsImage" style="font-weight:600">
                <span v-if="!(_comment.IsVerification == true && !_comment.AssignedTo_ID)">{{_comment.CreatedBy}}</span>
                {{ utils.formatDate(_comment.Created) }}
                <template v-if="shouldShowUpdatedInfo">
                    - {{ $t("Updated") }} {{ $formatDate(_comment.Updated) }}
                    <template v-if="_comment.UpdatedBy_ID && _comment.UpdatedBy && _comment.UpdatedBy_ID !== _comment.CreatedBy_ID">{{ $t("by") }} {{ _comment.UpdatedBy }}</template>
                </template>
            </small>            
            <div class="dropdown d-inline" v-if="(showActions || allowEdit) && !_comment.IsImage && _comment.CreatedBy_ID" >
                <button class="btn p-0 px-2 border-0" type="button" data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height: 17px;vertical-align: text-top;">   
                    <i class="bi bi-chevron-down"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">      
                    <template v-if="canAdministrateItem">
                        <button class="dropdown-item" @click="editItem" v-if="!_comment.Recipients">{{ $t("Edit") }}</button>   
                        <button class="dropdown-item" @click="deleteComment" v-if="!_comment.Recipients">{{ $t("Delete") }}</button>
                        <div class="dropdown-divider" v-if="!_comment.Recipients"></div>
                    </template>                                        
                    <button class="dropdown-item" @click="newItem({ type:'action' })">{{$t("Add Action / Comment")}}</button>   
                    <OFileUploadButton :dataObject="props.dataobject" :beforeUpload="() => beforeUpload(_comment)" >
                        <button class="dropdown-item">{{$t("Upload File(s)")}}</button>
                    </OFileUploadButton>
                </div>
            </div>
        </div>
        <div v-if="!isEditing">
            <div>{{_comment.Title}}</div>
            <div style="line-height: 1.2;white-space: pre-wrap;margin-top: 0.15rem;" v-url-to-anchor-tag="_comment.Comment"></div>
            <div v-if="_comment.FileRef">
                <OAttachments v-if="offline || _comment.IsImage" :dataObject="props.dataobject" showFileNames imageEditor :filterCallback="filterForComment" :overrideIndex="index" :offline="offline"></OAttachments>
                <a v-if="!offline && !_comment.IsImage" :href="`/api/file/download/${props.dataobject.viewName}/${_comment.PrimKey}/${_comment.FileName}?height=60&autorotate=true`" >{{_comment.FileName}}</a>
            </div>
        </div>
        <div v-else-if="isEditing || isNew" class="mt-1 row">
            <div class="form-group col-12">
                <OTextArea v-model="_comment.Comment" class="border d-inline-block p-1 bg-white form-control" ref="textArea" autoGrow noResize />
            </div>
            <!-- <div class="form-group col-lg-3 col-6"> -->
                <!-- <label for="content-first-released">{{ $t("Assigned To") }}</label> -->
                <!-- <OPersonsLookup --> 
                    <!-- :bind="sel => { _comment.AssignedTo_ID = sel.ID, _comment.AssignedTo = sel.Name; _comment.Role_ID = null; _comment.Role = null; setMobile(sel) }" -->
                    <!-- v-model="_comment.AssignedTo" --> 
                    <!-- :dataObject="_personDataObject" -->
                    <!-- class="form-control form-control-sm" /> -->
            <!-- </div> -->

            <!-- <div class="form-group col-lg-3 col-6"> -->
                <!-- <label>{{ $t("Role") }}</label> -->
                <!-- <ORolesLookup --> 
                    <!-- class="form-control form-control-sm" --> 
                    <!-- viewName="sviw_System_Roles" -->
                    <!-- :whereClause="`Closed IS NULL ${setRolesWhereClause()}`" -->
                    <!-- forceReloadOnOpen -->
                    <!-- :bind="selected => {_comment.Role_ID = selected.ID, _comment.Role = selected.Title; _comment.AssignedTo_ID = null, _comment.AssignedTo = null }" -->
                    <!-- v-model="_comment.Role"> -->
                    <!-- <template #target="{target}"> -->
                        <!-- <input :ref="target" v-model="_comment.Role" class="form-control form-control-sm" /> -->
                    <!-- </template> -->
                <!-- </ORolesLookup> -->
            <!-- </div> -->

            <!-- <div class="form-group col-lg-3 col-6" v-if="_comment.AssignedTo_ID || _comment.Role_ID"> -->
                <!-- <label for="due-date-input">{{ $t("Due Date") }}</label> -->
                <!-- <ODatePicker id="due-date-input" v-model="_comment.DueDate" class="form-control form-control-sm" format="Short Date" date /> -->
            <!-- </div> -->

            <!-- <div class="form-group col-lg-3 col-6" v-if="_comment.AssignedTo_ID != null && assignedToHasMobileNo"> -->
                <!-- <div class="form-check mt-4"> -->
                    <!-- <input class="form-check-input" type="checkbox" id="flexCheckDefault" v-model="_comment.AskForResponseViaSMS"> -->
                    <!-- <label class="form-check-label" for="flexCheckDefault"> -->
                        <!-- {{ $t("Ask for response via SMS") }} -->
                    <!-- </label> -->
                <!-- </div> -->
            <!-- </div> -->

            <div>
                <OActionSave class="btn btn-sm btn-link pt-0" :row="_comment" :disabled="isVerification && !(_comment.AssignedTo_ID || _comment.Role_ID)">{{ $t("Save") }}</OActionSave>
                <button class="btn btn-sm btn-link ms-1 pt-0" :row="_comment" @click="doCancel()" :dataObject="props.dataobject">{{ $t("Cancel") }}</button>
            </div>
        </div>  

        <div v-if="_comment.AssignedTo_ID && _comment.Role_ID == null && !_comment.IsVerification && !isEditing">
            <div class="form-group" v-if="!_comment.Completed">
                <span class="text-primary">{{ $t("Action Assigned To") }}: <span>{{_comment.AssignedTo}}</span><template v-if="_comment.DueDate">, {{ $t("Due Date") }}: {{ $formatDate(_comment.DueDate, "Short Date") }}</template></span>                
                <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type comment here to close action...')}`" autoGrow noResize :rows="1" />
                <div>
                    <OActionSave class="btn btn-sm btn-link" :row="_comment" :dataObject="props.dataobject">{{ $t("Save") }}</OActionSave>
                    <OActionCancel class="btn btn-sm btn-link ms-1" :row="_comment"  :dataObject="props.dataobject">{{ $t("Cancel") }}</OActionCancel>
                </div>
            </div>

            <div v-if="_comment.Completed" class="ms-3 mt-2">
                <span class="text-primary signature" >{{ $t("Action Completed By") }} <span>{{_comment.CompletedBy}}</span> {{ utils.formatDate(_comment.Completed) }}</span>
                <div class="dropdown d-inline" v-if="canEditCompletedActionResponses">
                    <button class="btn p-0 px-2 border-0" type="button" data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height: 17px;vertical-align: text-top;">   
                        <i class="bi bi-chevron-down"></i>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">      
                        <button class="dropdown-item" @click="isEditingActionResponse = true">{{ $t("Edit") }}</button>
                    </div>
                </div>
                <div v-if="!isEditingActionResponse" class="multiline-comment">
                    <i>{{_closeoutComment}}</i>
                </div>
                <div v-else>                    
                    <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type comment here to close action...')}`" autoGrow noResize :rows="1" />
                    <div>
                        <OActionSave class="btn btn-sm btn-link" :row="_comment" :dataObject="props.dataobject">{{ $t("Save") }}</OActionSave>
                        <OActionCancel class="btn btn-sm btn-link ms-1" :row="_comment" enabledWithoutChanges :dataObject="props.dataobject">{{ $t("Cancel") }}</OActionCancel>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="(_comment.IsVerification && !_comment.isNewRecord && !isEditing) || isEditingVerificationResponse">
            <span class="text-primary" v-if="_comment.Role_ID" :class="{'text-decoration-line-through':_comment.Completed}">
                {{$t("Role Responsible")}} : <span v-if="!_comment.OrgUnit_ID">{{_comment.Role}}</span><span>{{_comment.Role}}</span>
            </span>
            <span class="text-primary" v-if="!_comment.Completed && !_comment.Role_ID">{{ $t("Assigned To") }}: <span>{{_comment.AssignedTo}}</span></span>
            <span v-if="_comment.DueDate && !_comment.Completed">, {{ $t("Due Date") }}: {{ $formatDate(_comment.DueDate, "Short Date") }}</span>
            <div v-if="((!_comment.Approved && !_comment.Rejected) || isEditingVerificationResponse) && _comment.CanCompleteVerification">                
                <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type here to complete...')}`" autoGrow noResize :rows="1" />
                <div class="mt-2">
                    <button class="btn btn-sm btn-primary me-2" @click="approveVerification" >{{$t("Approve")}}</button>
                    <button class="btn btn-sm btn-outline-secondary me-3" @click="rejectVerification" >{{$t("Reject")}}</button>
                    <OActionCancel class="btn btn-sm btn-outline-secondary" :row="_comment" v-if="_comment.hasChanges || isEditingVerificationResponse" :enabledWithoutChanges="isEditingVerificationResponse">{{$t("Cancel")}}</OActionCancel>
                </div>
            </div>
            <template v-if="_comment.Completed && !isEditingVerificationResponse">
                <span class="text-muted" :class="{'ms-1': !_comment.Completed}">
                    &nbsp<template v-if="_comment.Approved">{{$t("Approved by")}}</template><template v-else>{{$t("Rejected by")}}</template> <span>{{_comment.CompletedBy}}</span> {{ utils.formatDate(_comment.Completed) }}
                </span>
                <button v-if="_comment.CanCompleteVerification" class="btn p-0 px-2 border-0" type="button" data-bs-toggle="dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="height: 17px;vertical-align: text-top;">   
                    <i class="bi bi-chevron-down"></i>
                </button>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <button class="dropdown-item" @click="isEditingVerificationResponse = true">{{$t("Edit")}}</button>
                </div>
                <div class="multiline-comment">&nbsp<i>{{_closeoutComment}}</i></div>
            </template>
        </div>

        <div v-if="!_comment.IsVerification && _comment.Role_ID != null && _comment.AssignedTo_ID == null && !_comment.isNewRecord && !isEditing">
            <span class="text-primary" :class="{'text-decoration-line-through':_comment.Completed}">
                {{$t("Role Responsible")}} : <span v-if="!_comment.Role_ID">{{_comment.Role}}</span><span v-else>{{_comment.Role}}</span>
            </span>
            <template v-if="!comment.Completed">                
                <OTextArea class="form-control form-control-sm" v-model="_closeoutComment" :placeholder="`${$t('Type comment here to close task...')}`" autoGrow noResize :rows="1" />
                <div>
                    <OActionSave class="btn btn-sm btn-link" :row="_comment" :dataObject="props.dataobject">{{ $t("Save") }}</OActionSave>
                    <OActionCancel class="btn btn-sm btn-link ms-1" :row="_comment" enabledWithoutChanges :dataObject="props.dataobject">{{ $t("Cancel") }}</OActionCancel>
                </div>
            </template>
            <template v-else>
                <div class="multiline-comment ms-1">
                    <i>{{_closeoutComment}}</i>
                </div>
                <span class="ms-1 text-muted" >{{ $t("Completed By") }} {{_comment.CompletedBy}} {{ utils.formatDate(_comment.Completed) }}</span>
            </template>
        </div>

        <Journal 
            v-if="!isNew" 
            :ownerid='_comment[props.ownerField]' 
            :ownerField="ownerField"
            ref="journalRef" 
            :parentCommentId="comment[parentCommentParentField]"
            :parentCommentField="parentCommentField"
            :parentCommentParentField="parentCommentParentField"          
            :allowEdit="allowEdit" 
            :dataobject="props.dataobject" 
            :class="{'ms-3 mt-2':parentCommentId}" 
            :personDataObject="props.personDataObject" 
            :sendEmailProcedure="sendEmailProcedure"
            :isClientWorkspace="props.isClientWorkspace"
        />
    </div>

    <OModal ref="imageArchiveModal">
        <div class="modal-dialog modal-xl">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Add Image From Archive") }}
                        </h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="h-100 d-flex flex-column justify-content-between">
                        <OImageBrowser @addImages="addImages" style="height: 70vh; overflow-y: auto;" />
                    </div>
                </div>
            </div>
        </div>
    </OModal>
    <OModal ref="sendReminderModal">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <div>
                        <h4 class="modal-title" id="staticBackdropLabel">
                            {{ $t("Send Reminder") }}
                        </h4>
                    </div>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <OTextArea style="width:100%;min-height:350px" spellcheck="false" v-model="reminderText" class="border d-inline-block p-2 bg-white  form-control form-control-sm"  autoGrow   />
                </div>
                <div class="modal-footer">
                    <button class="btn btn-sm btn-primary" @click="sendReminderConfirm">{{ ("Send") }}</button>
                    <button class="btn btn-sm btn-outline-primary" data-bs-dismiss="modal">{{ ("Cancel") }}</button>
                </div>
            </div>
        </div>
    </OModal>
</template>

<script setup>
    import { computed, ref, onMounted, onUnmounted } from 'vue'; 
    import { utils } from 'o365-utils';   
    import { OActionSave, OActionCancel } from 'o365-data-components';
    import Journal from './UI.Journal.Journal.vue';
    import { OFileUploadButton } from 'o365-fileupload';
    import { OAttachments } from 'o365-fileupload';
    import { OImageBrowser } from 'o365-data-components';
    import { alert } from 'o365-vue-services';
    import vUrlToAnchorTag from "o365.vue.directive.urlToAnchorTag.ts";
    import { getOrCreateProcedure, userSession } from 'o365-modules';

    const emit = defineEmits(['cancel', 'new']);

    const showPinned = ref(false);
    const imageArchiveModal = ref();
    const sendReminderModal = ref();
    const reminderText = ref("");

    const props = defineProps({
        ownerid: { type: [Number, String], default: null },
        ownerField: { type: String, default: "Step_ID" },
        parentCommentId: { type: [Number, String] },
        parentCommentField: { type: String, default: "ParentComment_ID" },
        parentCommentParentField: { type: String, default: "ID" },
        dataobject: Object,
        comment: Object,
        index: Number,
        allowEdit: Boolean,        
        personDataObject: Object,
        isVerification: Boolean,
        isNew: Boolean,
        sendEmailProcedure: { type: String, default: null },
        sendReminderProcedure : { type: String, default: null },
        showActions: { type: Boolean, default: true },
        isClientWorkspace: Boolean,
        offline: { type: Boolean, default: false },
    });    

    const afterSaveCb = props.dataobject.on("AfterSave", () => {
        isEditing.value = false;
        isEditingActionResponse.value = false;
        isEditingVerificationResponse.value = false;
        isNew.value = false;
    });    

    const beforeCreateCb = props.dataobject.on("BeforeCreate", e => {        
        if (!e.values[props.ownerField]) {
            e.values[props.ownerField] = props.ownerid; 
        }                
    });

    const beforeUpdateCb = props.dataobject.on("BeforeUpdate", e => {
        if (e.values["CloseOutComment"] || e.values["CloseoutComment"]){
            e.values["Completed"] = new Date();
            e.values["CompletedBy_ID"] = userSession.personId;

            // Set Updated to be equal to its current value so that the field is not updated in trigger when we complete an action.
            if (e.values["PrimKey"] === _comment.value.PrimKey && _comment.value.Updated && _comment.value.UpdatedBy_ID) {
                e.values["Updated"] = _comment.value.Updated;
                e.values["UpdatedBy_ID"] = _comment.value.UpdatedBy_ID;
            }
        }
    });

    const changesCancelledCb = props.dataobject.on("ChangesCancelled", () => {
        isEditing.value = false;
        isEditingActionResponse.value = false;
        isEditingVerificationResponse.value = false;
        isNew.value = false;
    });

    function setRolesWhereClause(){
        if(props.isClientWorkspace){
            return "AND Capabilities LIKE '%Can use Client Workspace%'"
        } else {
            return "";
        } 
    }

    function addImages(selectedImages){
        selectedImages.forEach(x => {
            var obj = new Object();
            obj.FileName = x.FileName;
            obj.FileRef = x.FileRef;
            obj.FileSize = x.FileSize;
            obj.ParentComment_ID = props.parentCommentId;
            obj[props.parentCommentField] = props.parentCommentId ?? null;

            props.dataobject.createNew(obj, false);
        });
        props.dataobject.save();
        imageArchiveModal.value?.hide();
    }

    onUnmounted(() => {
        // Clean up data object event listeners if this JournalItem is removed.
        afterSaveCb && afterSaveCb();
        beforeCreateCb && beforeCreateCb();
        changesCancelledCb && changesCancelledCb();
        beforeUpdateCb && beforeUpdateCb();
    });


    function filterForComment(x) {
        const keyColumn = x.ID !== undefined ? "ID" : "PrimKey"; // If ID column does not exist on record, we use PrimKey instead (offline)
        return x[keyColumn] === _comment.value[keyColumn] && x.IsImage == _comment.value.IsImage;
    }

    const beforeUpload = (comment) => {
        var obj = new Object();
        obj[props.ownerField] = comment[props.ownerField];
        obj[props.parentCommentField] = comment[props.parentCommentField];
        return obj;
    }

    const newComment = ref();
    const textArea = ref(null);
    const journalRef = ref(null);
    const _comment = computed({
        get() {
            return props.comment ?? newComment.value
        }, 
        set(value) 
        {
            newComment.value = value;
        }
    });

    // Some views call this column CloseoutComment and others CloseOutComment (note case differences)
    const _closeoutComment = computed({
        get() {
            return _comment.value.CloseoutComment || _comment.value.CloseOutComment
        },
        set(value) {            
            if (props.dataobject.fields["CloseoutComment"]) {
                _comment.value.CloseoutComment = value;
            } else {
                _comment.value.CloseOutComment = value;
            }
        }
    });

    const canEditCompletedActionResponses = computed(() => userSession.personId === _comment.value.CompletedBy_ID && _comment.value.Completed);
    const canAdministrateItem = computed(() => _comment.value.CanAdministerComments || userSession.personId === _comment.value.CreatedBy_ID);
    const shouldShowUpdatedInfo = computed(() => {
        if (!_comment.value.Updated) {
            return false;
        }

        const updated = new Date(_comment.value.Updated);
        const completed = _comment.value.Completed && new Date(_comment.value.Completed);

        if (completed) {
            const diff = Math.abs(completed.getTime() - updated.getTime());
            return diff >= 2000;
        }

        const created = new Date(_comment.value.Created);
        const diff = Math.abs(updated.getTime() - created.getTime());
        return updated > created && diff >= 2000;
    });

    const isEditing = ref(false);
    const isEditingActionResponse = ref(false);
    const isEditingVerificationResponse = ref(false);
    const isNew = ref(props.isNew);
    const allowEdit = ref(props.allowEdit);
    const isVerification = computed(() => props.isVerification || _comment.value?.IsVerification);

    onMounted(() => {
        if(props.index == null){
            isEditing.value = true;
        }
    });

    if(!props.comment){
        props.dataobject.disableSaveOncurrentIndexChange = true
        props.dataobject.createNewAtTheEnd = false;

        var obj = new Object();
        obj[props.parentCommentField] = props.parentCommentId ?? null;
        obj.IsVerification = props.isVerification;
        obj[props.ownerField] = props.ownerid;

        const item = props.dataobject.createNew(obj, false);
        _comment.value = item;
    }

    function editItem(){
        isEditing.value = true;
    }

    function newItem(pObj){
        if(pObj.type === "verification"){
            journalRef.value.newItem(true);
        } else {
            journalRef.value.newItem(false);
        }
    }
    
    function doCancel(){
        props.dataobject.cancelChanges();
        emit('cancel');
    }

    function deleteComment(){
        props.dataobject.deleteItem(props.comment);
    }    

    function scrollIntoView(){
        textArea.value.activateEditor()
    }

    defineExpose({
        scrollIntoView
    });

    async function approveVerification() {
        _comment.value.Approved = new Date();
        _comment.value.Rejected = null;
        _comment.value.Completed = new Date();
        _comment.value.CompletedBy_ID = userSession.personId;
        await _comment.value.save().then(()=>{

        }).catch((e)=>{
            var vTmpValue = _closeoutComment.value;
            _comment.value.cancelChanges();
            _closeoutComment.value = vTmpValue;
        });
    }

    function rejectVerification() {
        if (_closeoutComment.value == null){
            return alert($t("Rejected verifications must have a closeout comment."), 'warning', { autohide: true, delay: 3000 })
        }        
        _comment.value.Rejected = new Date();
        _comment.value.Approved = null;
        _comment.value.Completed = new Date();
        _comment.value.CompletedBy_ID = userSession.personId;
        _comment.value.save();
    }

    const sendReminder = () =>{
        const procSendReminder = getOrCreateProcedure({ id:"procSendReminder", procedureName: props.sendReminderProcedure ??  "astp_Workflow_ItemActionReminder" });

        const url = new URL(location.href);
        const hostname = url.hostname;
        const parts = hostname.split('.');
        const subdomain = parts.length > 2 ? parts[0] : null;


        procSendReminder.execute({ Action_ID: _comment.value.ID, Domain: subdomain })
        .then((pData) => {            
            if(pData.Table[0].EmailBody){
                reminderText.value = pData.Table[0].EmailBody;
                sendReminderModal.value?.show();
            }
        });
    }
    
    const sendReminderConfirm = () =>{
        const procSendReminder = getOrCreateProcedure({ id:"procSendReminder", procedureName: props.sendReminderProcedure ??  "astp_Workflow_ItemActionReminder" });

        const url = new URL(location.href);
        const hostname = url.hostname;
        const parts = hostname.split('.');
        const subdomain = parts.length > 2 ? parts[0] : null;


        procSendReminder.execute({ Action_ID: _comment.value.ID, Domain: subdomain, Text: reminderText.value })
        .then((pData) => {
            alert('Reminder sent', 'success', { autohide: true, delay:2500});
            sendReminderModal.value?.hide();
        });
    }
</script>

<style scoped>
    .multiline-comment {
        white-space: pre-wrap;
        overflow-wrap: break-word;
    }
</style>